.rmdp-week-day {
  color: #34515e;
}

.rmdp-arrow {
  border-color: #34515e;
}

.rmdp-arrow-container:hover {
  background-color: #2ac940;
}

.rmdp-day,
.rmdp-week-day {
  width: 38px;
  height: 38px;
}

.rmdp-day.rmdp-today span {
  background-color: #6a6a6a;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #2ac940;
  opacity: 0.5;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #2ac940;
}
