.main-content {
  position: absolute;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f8f8f8;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
}
